<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <div>
          <v-text-field
            v-model="formData.id"
            placeholder="Buscar Id"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            append-icon="mdi-magnify"
            @click:append="
              page = 1;
              formData.status = null;
              buscar(formData);
            "
            @click:clear="
              page = 1;
              (formData.id = null), (formData.status = 'Grupo|Ativa');
              buscar(formData);
            "
          >
          </v-text-field>
        </div>
        <v-btn
          small
          depressed
          color="fmq_gray"
          dark
          to="/solicitacao-criar"
          v-if="solicitacaoCriar && !isColaboradorTr"
          >Nova Solicitação</v-btn
        >
      </div>
      <div class="d-flex align-center justify-start mb-4">
        <div v-for="dado in dadosDictinary" :key="dado.text">
          <v-chip
            :color="dado.color"
            class="ma-2 font-weight-bold"
            text-color="#fff"
            small
            >{{ dados[dado.value] }} - {{ dado.text }}</v-chip
          >
        </div>
      </div>
      <SolicitacoesTabela
        :headers="headers"
        :solicitacoes="item"
        :loading="loading"
        :total="total"
        :pageText="pageText"
        :pageNumber="page"
        @handleFilter="filter"
        @excluir="excluirModal"
        @iniciarAtendimento="
          dialogAssumir = true;
          itemId = $event.id;
          itemStatusCarta = $event.statusCarta;
        "
        @assumirAtendimento="
          dialogAssumir = true;
          itemId = $event.id;
          itemStatusCarta = $event.statusCarta;
        "
        @encaminhar="
          dialogEncaminhar = true;
          itemId = $event;
        "
        @consulta="
          dialogConsulta = true;
          itemId = $event;
        "
        @aprovacao="
          dialogAprovacao = true;
          itemId = $event.id;
          formDataAprovacao.aprovadorId = $event.produto.aprovadorIc;
        "
        @postagem="
          dialogPostagem = true;
          itemId = $event.id;
          formDataPostagem.destinatario = $event.representante.nome;
          formDataPostagem.confirmado = true;
          formDataPostagem.cep = $event.representante.cep;
          formDataPostagem.estado = $event.representante.estado;
          formDataPostagem.cidade = $event.representante.cidade;
          formDataPostagem.bairro = $event.representante.bairro;
          formDataPostagem.logradouro = $event.representante.rua;
          formDataPostagem.numero = $event.representante.numero;
          formDataPostagem.complemento = $event.representante.complemento;
          formDataPostagem.postandoEm = null;
        "
      >
        <template v-slot:status>
          <v-autocomplete
            v-model="formData.status"
            :items="listStatus"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="value"
            placeholder="Status"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`colaborador.nome`]>
          <v-autocomplete
            v-model="formData.colaboradorId"
            :items="listColaborador"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Colaborador"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`representante.nome`]>
          <v-autocomplete
            v-model="formData.representanteId"
            :items="listRepresentantes"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Representante"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`produto.nome`]>
          <v-autocomplete
            v-model="formData.produtosId"
            :items="listProdutos"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Produtos"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:assunto>
          <v-autocomplete
            v-model="formData.assunto"
            :items="listAssuntos"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="value"
            placeholder="Motivos"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:conteudo>
          <v-text-field
            v-model="formData.conteudo"
            placeholder="Conteúdo"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscar(formData);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`medicoSolicitante.nome`]>
          <v-autocomplete
            v-model="formData.medicosSolicitantesId"
            :items="listMedicosSolicitantes"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Médico Solicitante"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`aprovador.nome`]>
          <v-autocomplete
            v-model="formData.aprovadorId"
            :items="listMedicoAprovadores"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Aprovador"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`modoEnvio`]>
          <v-autocomplete
            v-model="modoEnvio"
            :items="listModoEnvio"
            hide-no-data
            hide-selected
            placeholder="Modo de Envio"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscar(formData);
            "
            @click:clear="
              formData.envioCorreios = false;
              formData.envioEmail = false;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:createdAt>
          <v-menu
            ref="menuCreatedAt"
            v-model="menuCreatedAt"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datasCreatedAt"
                solo
                flat
                dense
                :hide-details="true"
                readonly
                v-bind="attrs"
                v-on="on"
                :clearable="true"
                placeholder="Aberto em"
                hint="MM/DD/YYYY format"
                @click:clear="
                  page = 1;
                  datasCreatedAt = [];
                  buscar(formData);
                "
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datasCreatedAt"
              range
              no-title
              scrollable
              locale="pt-br"
              :max="nowDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuCreatedAt = false">
                Fechar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  menuCreatedAt = false;
                  page = 1;
                  buscar(formData);
                "
              >
                Selecionar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </template>

        <template v-slot:concluidoEm>
          <v-menu
            ref="menuCreatedAt"
            v-model="menuConcluidoEm"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datasConcluidoEm"
                solo
                flat
                dense
                :hide-details="true"
                readonly
                v-bind="attrs"
                v-on="on"
                :clearable="true"
                placeholder="Finalizado em"
                hint="MM/DD/YYYY format"
                @click:clear="
                  page = 1;
                  datasConcluidoEm = [];
                  buscar(formData);
                "
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datasConcluidoEm"
              range
              no-title
              scrollable
              locale="pt-br"
              :max="nowDate"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuConcluidoEm = false">
                Fechar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  menuConcluidoEm = false;
                  page = 1;
                  buscar(formData);
                "
              >
                Selecionar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-center">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </SolicitacoesTabela>
    </div>
    <AlertConfirmation
      :dialog="dialog"
      :dialogMessage="dialogMessage"
      @close="dialog = false"
      @accept="excluir"
    />
    <AlertConfirmation
      :dialog="dialogAssumir"
      :thirdButton="true"
      dialogMessage="Gostaria de assumir o atendimento desta solicitação?"
      @close="dialogAssumir = false"
      @accept="assumir"
      @thirdEvent="iniciar"
      icon=""
      thirdButtonText="Ir para carta resposta"
    />
    <AlertSuccess
      :dialog="success"
      :dialogMessage="dialogMessage"
      @close="
        buscar(formData);
        success = false;
      "
    />
    <AlertError :alertError="error" :messageError="messageError" />
    <FormModal
      :loading="loadingEncaminhar"
      :dialog="dialogEncaminhar"
      dialogMessage="Encaminhar solicitação"
      @close="dialogEncaminhar = false"
      @accept="encaminhar"
    >
      <v-form ref="formEncaminhar" v-model="valid" lazy-validation>
        <v-textarea
          outlined
          label="Comentário"
          rows="2"
          v-model="formDataEncaminhar.comentario"
          required
          :rules="rules.genericRules"
        ></v-textarea>
        <v-switch
          v-model="formDataEncaminhar.visivelApp"
          label="Comentário visível para o representante?"
          inset
          :hide-details="true"
          class="mt-0 pa-0 mb-4"
          color="cyan"
        ></v-switch>
      </v-form>
    </FormModal>
    <FormModal
      :loading="loadingConsulta"
      :dialog="dialogConsulta"
      dialogMessage="Encaminha para consulta interna"
      @close="dialogConsulta = false"
      @accept="consulta"
    >
      <v-form ref="formConsulta" v-model="valid" lazy-validation>
        <v-textarea
          outlined
          label="Comentário"
          rows="2"
          v-model="formDataConsulta.comentario"
          required
          :rules="rules.genericRules"
        ></v-textarea>
        <v-switch
          v-model="formDataConsulta.visivelApp"
          label="Comentário visível para o representante?"
          inset
          :hide-details="true"
          class="mt-0 pa-0 mb-4"
          color="cyan"
        ></v-switch>
      </v-form>
    </FormModal>
    <FormModal
      :loading="loadingAprovacao"
      :dialog="dialogAprovacao"
      dialogMessage="Encaminha para aprovação"
      @close="dialogAprovacao = false"
      @accept="aprovacao"
    >
      <v-form ref="formAprovacao" v-model="valid" lazy-validation>
        <v-autocomplete
          v-model="formDataAprovacao.aprovadorId"
          :items="listMedicoAprovadores"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          label="Médico aprovador"
          placeholder="Médico aprovador"
          outlined
          dense
          small-chips
          required
          :rules="rules.selectRules"
          deletable-chips
        ></v-autocomplete>
        <v-textarea
          outlined
          label="Comentário"
          rows="2"
          v-model="formDataAprovacao.comentario"
          required
          :rules="rules.genericRules"
        ></v-textarea>
      </v-form>
    </FormModal>
    <FormModal
      :loading="loadingPostagem"
      :dialog="dialogPostagem"
      :btnLabelSend="'Confirmar Envio'"
      dialogMessage="Informações de Postagem"
      @close="dialogPostagem = false"
      @accept="postagem"
    >
      <v-form ref="formPostagem" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="formDataPostagem.destinatario"
              placeholder="Destinatário"
              outlined
              dense
              :clearable="true"
              label="Destinatário"
              required
              :rules="rules.genericRules"
            >
            </v-text-field>
            <v-autocomplete
              v-model="formDataPostagem.estado"
              :items="estados"
              hide-no-data
              hide-selected
              item-text="text"
              item-value="value"
              placeholder="Digite o estado"
              outlined
              dense
              :clearable="true"
              label="Estado"
              required
              :rules="rules.selectRules"
            ></v-autocomplete>
            <v-text-field
              v-model="formDataPostagem.bairro"
              placeholder="Digite o bairro"
              outlined
              dense
              :clearable="true"
              label="Bairro"
              required
              :rules="rules.genericRules"
            >
            </v-text-field>
            <v-text-field
              v-model="formDataPostagem.numero"
              placeholder="Digite o numero"
              outlined
              dense
              :clearable="true"
              label="Numero"
              required
              :rules="rules.genericRules"
            >
            </v-text-field>
            <v-menu
              ref="postadoEm"
              v-model="postadoEm"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formDataPostagem.postadoEm"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :clearable="true"
                  placeholder="Postado em"
                  label="Postado em"
                  required
                  :rules="rules.genericRules"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formDataPostagem.postadoEm"
                no-title
                scrollable
                locale="pt-br"
                :max="nowDate"
                @input="postadoEm = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="formDataPostagem.cep"
              placeholder="Digite o CEP"
              outlined
              dense
              :clearable="true"
              label="CEP"
              required
              :rules="rules.selectRules"
              v-mask="'#####-###'"
            >
            </v-text-field>
            <v-text-field
              v-model="formDataPostagem.cidade"
              placeholder="Digite a cidade"
              outlined
              dense
              :clearable="true"
              label="Cidade"
              required
              :rules="rules.genericRules"
            >
            </v-text-field>
            <v-text-field
              v-model="formDataPostagem.logradouro"
              placeholder="Digite o endereço"
              outlined
              dense
              :clearable="true"
              label="Endereço"
              required
              :rules="rules.genericRules"
            >
            </v-text-field>
            <v-text-field
              v-model="formDataPostagem.complemento"
              placeholder="Digite o endereço"
              outlined
              dense
              :clearable="true"
              label="Complemento"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </FormModal>
  </div>
</template>

<script>
import { ESTADOS_LIST } from "@/utils/constants.js";
import { rules } from "@/utils/rules.js";
import SolicitacoesTabela from "@/components/solicitacoes/SolicitacoesTabela.vue";
import {
  buscarSolicitacao,
  dadosSolicitacao,
  excluirSolicitacao,
  iniciarAtendimentoSolicitacao,
  encaminharSolicitacao,
  consultaInternaSolicitacao,
  encaminharAprovacaSolicitacao,
  dadosPostagemSolicitacao,
} from "@/services/solicitacoes";
import { listarProdutos } from "@/services/produtos";
import { listarMedicoSolicitante } from "@/services/medico-solicitante";
import {
  listarMedicoAprovadores,
  listFuncionarios,
  listarRepresentantes,
} from "@/services/user";
export default {
  name: "Solicitacoes",
  components: { SolicitacoesTabela },
  data: () => ({
    breadcrumbs: [
      {
        text: "Lista de Solicitações",
        disabled: true,
        to: "",
      },
    ],
    headers: [
      { text: "Ações", value: "id", sortable: false },
      { text: "Status", value: "status", width: "10px" },
      { text: "Colaborador", value: "colaborador.nome" },
      { text: "Representante", value: "representante.nome" },
      { text: "Produto", value: "produto.nome" },
      { text: "Motivo", value: "assunto" },
      { text: "Relato", value: "conteudo", width: "300px" },
      { text: "Médico Solicitante", value: "medicoSolicitante.nome" },
      { text: "Aprovador", value: "aprovador.nome" },
      { text: "Modo de envio", value: "modoEnvio" },
      { text: "Aberto em", value: "createdAt" },
      { text: "Finalizado em", value: "concluidoEm" },
    ],
    item: [],
    total: null,
    pageText: null,
    loading: false,
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: 15,
      id: null,
      mesasId: 1,
      status: "Grupo|Ativa",
      colaboradorId: null,
      representanteId: null,
      produtosId: null,
      assunto: null,
      conteudo: null,
      medicosSolicitantesId: null,
      envioEmail: false,
      envioCorreios: false,
    },
    modoEnvio: null,
    menuCreatedAt: false,
    datasCreatedAt: [],
    menuConcluidoEm: false,
    datasConcluidoEm: [],
    nowDate: new Date().toISOString().slice(0, 10),
    page: 1,
    dialog: false,
    dialogAssumir: false,
    dialogMessage: null,
    itemId: null,
    itemStatusCarta: null,
    error: false,
    success: false,
    messageError: null,
    listStatus: [
      { header: "Status" },
      {
        name: "Em Progresso",
        value: "Grupo|Ativa",
      },
      {
        name: "Todas",
        value: "Grupo|Todas",
      },
      { header: "Solicitação" },
      {
        name: "Aberto",
        value: "Aberto",
      },
      {
        name: "Em andamento",
        value: "Em andamento",
      },
      {
        name: "Troca de mesa",
        value: "Troca de mesa",
      },
      {
        name: "Aguardando resposta",
        value: "Aguardando resposta",
      },
      {
        name: "Consulta interna",
        value: "Consulta interna",
      },
      {
        name: "Respondido",
        value: "Respondido",
      },
      {
        name: "Pendente de aceite",
        value: "Pendente de aceite",
      },
      {
        name: "Em aprovação",
        value: "Carta|Em aprovação",
      },
      {
        name: "Aprovada",
        value: "Carta|Aprovada",
      },
      {
        name: "Reprovada",
        value: "Carta|Reprovada",
      },
      {
        name: "Finalizado",
        value: "Solicitação|Finalizado",
      },
      {
        name: "Pendente para postagem",
        value: "Carta|Aguardando envio",
      },
    ],
    listColaborador: [],
    listRepresentantes: [],
    listProdutos: [],
    listAssuntos: [
      { name: "Comparativo", value: "Comparativo" },
      { name: "Comprovação de segurança", value: "Comprovação de segurança" },
      {
        name: "Comprovação de eficácia e segurança",
        value: "Comprovação de eficácia e segurança",
      },
      { name: "Mecanismo de ação", value: "Mecanismo de ação" },
      {
        name: "Material de marketing (Separata ou Monografia)",
        value: "Material de marketing (Separata ou Monografia)",
      },
      { name: "Modo de uso/posologia", value: "Modo de uso/posologia" },
      { name: "Composição do produto", value: "Composição do produto" },
      { name: "Referência bibliográfica", value: "Referência bibliográfica" },
      { name: "Padronização", value: "Padronização" },
      { name: "Outros", value: "Outros" },
      { name: "Todos", value: null },
    ],
    listMedicosSolicitantes: [],
    listMedicoAprovadores: [],
    listModoEnvio: ["E-mail", "Correios"],
    dados: [],
    dadosDictinary: {
      medicosPendente: {
        color: "#f6cf23",
        text: "Em Andamento",
        value: "solicitacoesAndamento",
      },
      solicitacoesAbertas: {
        color: "#40db6e",
        text: "Abertas",
        value: "solicitacoesAbertas",
      },
      solicitacoesRespondido: {
        color: "#ff7900",
        text: "Mensagens recebidas",
        value: "solicitacoesRespondido",
      },
      solicitacoesConsultaInterna: {
        color: "#9f9f9f",
        text: "Consultas interna",
        value: "solicitacoesConsultaInterna",
      },
      solicitacoesEmAprovacao: {
        color: "#993399",
        text: "Em aprovação",
        value: "solicitacoesEmAprovacao",
      },
      solicitacoesAprovada: {
        color: "#00ff00",
        text: "Aprovadas",
        value: "solicitacoesAprovada",
      },
      solicitacoesReprovada: {
        color: "#cf0e0e",
        text: "Precisa de alteração",
        value: "solicitacoesReprovada",
      },
    },
    rules: rules,
    valid: true,
    formDataEncaminhar: {
      comentario: null,
      visivelApp: false,
    },
    dialogEncaminhar: false,
    loadingEncaminhar: false,
    formDataConsulta: {
      comentario: null,
      visivelApp: false,
    },
    dialogConsulta: false,
    loadingConsulta: false,
    formDataAprovacao: {
      comentario: null,
      aprovadorId: [],
    },
    dialogAprovacao: false,
    loadingAprovacao: false,
    formDataPostagem: {
      destinatario: null,
      cep: null,
      estado: null,
      cidade: null,
      bairro: null,
      logradouro: null,
      numero: null,
      complemento: null,
      postandoEm: null,
      confirmado: false,
    },
    dialogPostagem: false,
    loadingPostagem: false,
    estados: ESTADOS_LIST,
    postadoEm: false,
    excludeTypePermission: ["Colaborador", "Administrador de mesa"],
  }),
  created() {
    if (
      this.$store.state.user.data.funcionario.mesaId === 2 &&
      this.excludeTypePermission.includes(this.roleUser)
    ) {
      this.$router.push("/treinamento");
    }
    if (this.$store.state.solicitacoesSearchIc.length) {
      this.formData = { ...this.$store.state.solicitacoesSearchIc[0] };
      if (this.formData.envioEmail === true) {
        this.modoEnvio = "E-mail";
      }
      if (this.formData.envioCorreios === true) {
        this.modoEnvio = "Correios";
      }
      if (this.formData.dataCriacaoInicio && this.formData.dataCriacaoFim) {
        this.datasCreatedAt = [
          this.formData.dataCriacaoInicio,
          this.formData.dataCriacaoFim,
        ];
      }
      if (
        this.formData.dataConclusaoInicio &&
        this.formData.dataConclusaoInicio
      )
        this.datasConcluidoEm = [
          this.formData.dataConclusaoInicio,
          this.formData.dataConclusaoFim,
        ];
    }
    this.buscar(this.formData);
    this.getDados(this.formData.mesasId);
    this.getColaborador();
    this.getRepresentantes();
    this.getProdutos();
    this.getMedicosSolicitantes();
    this.getMedicoAprovadores();
  },
  methods: {
    async buscar(payload) {
      if (payload.id === null) {
        delete payload.id;
      }
      if (this.modoEnvio === "E-mail") {
        payload.envioEmail = true;
        payload.envioCorreios = false;
      }
      if (this.modoEnvio === "Correios") {
        payload.envioCorreios = true;
        payload.envioEmail = false;
      }
      if (this.datasCreatedAt.length > 0) {
        payload.dataCriacaoInicio = this.datasCreatedAt[0];
        payload.dataCriacaoFim = this.datasCreatedAt[1];
      }
      if (this.datasConcluidoEm.length > 0) {
        payload.dataConclusaoInicio = this.datasConcluidoEm[0];
        payload.dataConclusaoFim = this.datasConcluidoEm[1];
      }
      this.$store.dispatch("setSolicitacoesSearchIc", payload);
      this.item = [];
      try {
        this.loading = true;
        const resp = await buscarSolicitacao(this.page, payload);
        this.item = resp.data.data;
        this.total = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$store.dispatch("setSolicitacoesSearchIc", {});
      this.formData.status = null;
      this.formData.colaboradorId = null;
      this.formData.produtosId = null;
      this.formData.assunto = null;
      this.formData.conteudo = null;
      this.formData.medicosSolicitantesId = null;
      this.formData.envioEmail = false;
      this.formData.envioCorreios = false;
      this.formData.modoEnvio = null;
      this.datasCreatedAt = [];
      delete this.formData.dataCriacaoInicio;
      delete this.formData.dataCriacaoFim;
      this.datasConcluidoEm = [];
      delete this.formData.dataConclusaoInicio;
      delete this.formData.dataConclusaoFim;
      this.page = 1;
      this.buscar(this.formData, this.page);
    },
    search() {
      this.page = 1;
      this.buscar(this.formData, this.page);
    },
    filter(event) {
      this.$store.dispatch("setSolicitacoesSearchIc", this.formData);
      switch (event.orderBy) {
        case "medicoSolicitante.nome":
          this.formData.orderBy = "medico_solicitante.nome";
          break;
        case "modoEnvio":
          this.formData.orderBy = "modo_envio";
          break;
        case "createdAt":
          this.formData.orderBy = "created_at";
          break;
        case "concluidoEm":
          this.formData.orderBy = "concluido_em";
          break;
        default:
          this.formData.orderBy = event.orderBy;
      }
      this.formData.orderSorted = event.orderSorted;
      this.formData.perPage = event.perPage;
      this.page = event.page;
      this.buscar(this.formData, this.page);
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirSolicitacao(this.itemId).then(() => {
          this.dialog = false;
          this.dialogMessage = "Solicitação excluida com sucesso";
          this.success = true;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async assumir() {
      this.error = false;
      try {
        await iniciarAtendimentoSolicitacao(this.itemId).then(() => {
          this.dialogAssumir = false;
          this.buscar(this.formData);
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async iniciar() {
      this.error = false;
      try {
        await iniciarAtendimentoSolicitacao(this.itemId).then(() => {
          this.$router.push({
            name: this.itemStatusCarta
              ? "CartasRespostaEditar"
              : "CartasRespostaCriar",
            params: { id: this.itemId },
          });
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async encaminhar() {
      if (this.formValidEncaminhar) {
        this.error = false;
        this.sucess = false;
        this.loadingEncaminhar = true;
        try {
          await encaminharSolicitacao(
            this.itemId,
            this.formDataEncaminhar
          ).then(() => {
            this.dialogMessage = "Solicitação encaminhada com sucesso";
            this.dialogEncaminhar = false;
            this.success = true;
          });
          this.loadingEncaminhar = false;
        } catch (e) {
          this.loadingEncaminhar = false;
          this.error = true;
          this.messageError = e.response.data.message;
        }
      }
    },
    async consulta() {
      if (this.formValidConsulta) {
        this.error = false;
        this.sucess = false;
        this.loadingConsulta = true;
        try {
          await consultaInternaSolicitacao(
            this.itemId,
            this.formDataConsulta
          ).then(() => {
            this.dialogMessage = "Solicitação encaminhada com sucesso";
            this.dialogConsulta = false;
            this.success = true;
          });
          this.loadingConsulta = false;
        } catch (e) {
          this.loadingConsulta = false;
          this.error = true;
          this.messageError = e.response.data.message;
        }
      }
    },
    async aprovacao() {
      if (this.formValidAprovacao) {
        this.error = false;
        this.sucess = false;
        this.loadingAprovacao = true;
        try {
          await encaminharAprovacaSolicitacao(
            this.itemId,
            this.formDataAprovacao
          ).then(() => {
            this.dialogMessage = "Solicitação encaminhada com sucesso";
            this.dialogAprovacao = false;
            this.success = true;
          });
          this.loadingAprovacao = false;
        } catch (e) {
          this.loadingAprovacao = false;
          this.error = true;
          this.messageError = e.response.data.message;
        }
      }
    },
    async postagem() {
      if (this.formValidPostagem) {
        this.error = false;
        this.sucess = false;
        this.loadingPostagem = true;
        try {
          await dadosPostagemSolicitacao(
            this.itemId,
            this.formDataPostagem
          ).then(() => {
            this.dialogMessage = "Solicitação postada com sucesso";
            this.dialogPostagem = false;
            this.success = true;
          });
          this.loadingPostagem = false;
        } catch (e) {
          this.loadingPostagem = false;
          this.error = true;
          this.messageError = e.response.data.message;
        }
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir solicitação?`;
      this.dialog = true;
      this.itemId = item.id;
    },
    async getColaborador() {
      const resp = await listFuncionarios();
      this.listColaborador = resp.data;
    },
    async getRepresentantes() {
      const resp = await listarRepresentantes();
      this.listRepresentantes = resp.data;
    },
    async getProdutos() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async getMedicosSolicitantes() {
      const resp = await listarMedicoSolicitante();
      this.listMedicosSolicitantes = resp.data;
    },
    async getMedicoAprovadores() {
      const resp = await listarMedicoAprovadores();
      this.listMedicoAprovadores = resp.data;
    },
    async getDados(id) {
      const resp = await dadosSolicitacao(id);
      this.dados = resp.data;
    },
    validateEncaminhar() {
      this.$refs.formEncaminhar.validate();
    },
    validateConsulta() {
      this.$refs.formConsulta.validate();
    },
    validateAprovacao() {
      this.$refs.formAprovacao.validate();
    },
    validatePostagem() {
      this.$refs.formPostagem.validate();
    },
  },
  computed: {
    solicitacaoCriar() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.criar"
      );
    },
    isColaboradorTr() {
      if (
        this.$store.state.user.data.tipo === "Colaborador" &&
        this.$store.state.user.data.funcionario.mesaId === 2
      ) {
        return true;
      } else {
        return false;
      }
    },
    formValidEncaminhar() {
      return this.$refs.formEncaminhar.validate();
    },
    formValidConsulta() {
      return this.$refs.formConsulta.validate();
    },
    formValidAprovacao() {
      return this.$refs.formAprovacao.validate();
    },
    formValidPostagem() {
      return this.$refs.formPostagem.validate();
    },
    roleUser() {
      return this.$store.state.user.data.tipo;
    },
  },
};
</script>

<style></style>
