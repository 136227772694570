<template>
  <v-data-table
    :headers="headers"
    :items="solicitacoes"
    :items-per-page="15"
    :page="pageNumber"
    class="elevation-1 tabela-solicitacoes"
    locale="pt-BR"
    loading-text="Carregando"
    no-data-text="Nenhum dado encontrado"
    :loading="loading"
    :server-items-length="total"
    @update:options="handleFilter"
    :footer-props="{
      'items-per-page-text': 'Solicitações por página:',
      'items-per-page-all-text': 'Todos',
      'show-first-last-page': true,
      'show-current-page': true,
      'page-text': pageText,
      'items-per-page-options': [100, 200, 350, 500],
    }"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <template v-for="(header, index) in headers">
          <th :key="index">
            <slot :name="header.value"> </slot>
          </th>
        </template>
      </tr>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <td :style="{ backgroundColor: item.cor }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="iconStatus[item.statusIcone].color"
              dark
              v-bind="attrs"
              v-on="on"
            >
              {{ iconStatus[item.statusIcone].icon }}
            </v-icon>
          </template>
          <span>{{ iconStatus[item.statusIcone].value }}</span>
        </v-tooltip>
      </td>
    </template>

    <template v-slot:[`item.colaborador.nome`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        {{ item.colaborador ? item.colaborador.nome : "" }}
      </td>
    </template>

    <template v-slot:[`item.representante.nome`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        {{ item.representante ? item.representante.nome : "" }}
      </td>
    </template>

    <template v-slot:[`item.produto.nome`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        {{ item.produto ? item.produto.nome : "" }}
      </td>
    </template>

    <template v-slot:[`item.assunto`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        {{ item.assunto }}
      </td>
    </template>

    <template v-slot:[`item.conteudo`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        {{ item.conteudo }}
      </td>
    </template>

    <template v-slot:[`item.medicoSolicitante.nome`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        {{ item.medicoSolicitante ? item.medicoSolicitante.nome : "" }}
      </td>
    </template>

    <template v-slot:[`item.aprovador.nome`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        {{ item.aprovador ? item.aprovador.nome : "" }}
      </td>
    </template>

    <template v-slot:[`item.modoEnvio`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        <div v-if="item.envioEmail">E-mail</div>
        <div v-if="item.envioCorreios">Correios</div>
      </td>
    </template>

    <template v-slot:[`item.createdAt`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        {{ item.createdAt | dataPtBrAberto }}
        {{ item.createdAt | dataPtBrAbertoDias }}
      </td>
    </template>

    <template v-slot:[`item.concluidoEm`]="{ item }">
      <td :style="{ backgroundColor: item.cor }" class="caption">
        {{ item.concluidoEm | dataTimePtBr }}
      </td>
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <td :style="{ backgroundColor: item.cor }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              depressed
              color="fmq_gray"
              dark
              v-bind="attrs"
              v-on="on"
              class="px-0"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>ID: {{item.id}}</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="goTo('SolicitacaoVisualizarAtendimento', item.id)"
              v-if="solicitacaoExibir"
            >
              <v-list-item-title>Visualizar</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="goTo('SolicitacoesEditar', item.id)"
              v-if="
                !checkStatus(
                  ['Em aprovação', 'Consulta interna', 'Finalizado'],
                  item.status
                ) &&
                solicitacaoEditar &&
                (mesaId === null || mesaId === 1) &&
                isRepresentante
              "
            >
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>

            <template
              v-if="
                !checkStatus(
                  ['Pendente de aceite', 'Finalizado'],
                  item.status
                ) &&
                solicitacaoIniciarAtendimento &&
                (mesaId === null || mesaId === 1)
              "
            >
              <v-list-item
                v-if="item.colaborador === null"
                @click="$emit('iniciarAtendimento', item)"
              >
                <v-list-item-title>Iniciar atendimento</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-else-if="
                  item.colaborador.id !== user.funcionario.id &&
                  (mesaId === null || mesaId === 1)
                "
                @click="$emit('assumirAtendimento', item)"
              >
                <v-list-item-title>Assumir atendimento</v-list-item-title>
              </v-list-item>

              <template
                v-else-if="
                  item.colaborador.id === user.funcionario.id &&
                  (mesaId === null || mesaId === 1)
                "
              >
                <v-list-item
                  v-if="
                    !checkStatus(
                      ['Aguardando resposta', 'Respondido', 'Consulta interna'],
                      item.status
                    )
                  "
                  @click="
                    goTo(
                      item.statusCarta
                        ? 'CartasRespostaEditar'
                        : 'CartasRespostaCriar',
                      item.id
                    )
                  "
                >
                  <v-list-item-title>Continuar atendimento</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="$emit('assumirAtendimento', item)">
                  <v-list-item-title>Continuar atendimento</v-list-item-title>
                </v-list-item>
              </template>
            </template>

            <v-list-item
              @click="$emit('encaminhar', item.id)"
              v-if="
                checkStatus(
                  ['Aberto', 'Em andamento', 'Troca de mesa'],
                  item.status
                ) &&
                solicitacaoEncaminhar &&
                (mesaId === null || mesaId === 1)
              "
            >
              <v-list-item-title>Encaminhar para outra mesa</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="$emit('postagem', item)"
              v-if="
                item.envioCorreios === 1 
                && checkStatus(['Finalizado'], item.status) 
                && solicitacaoCriarPostagem
                && (mesaId === null || mesaId === 1)
                && item.postagens.length === 0
              "
            >
              <v-list-item-title
                >Inserir informações sobre postagem</v-list-item-title
              >
            </v-list-item>

            <v-list-item
              @click="$emit('aprovacao', item)"
              v-if="
                !checkStatus(
                  [
                    'Pendente de aceite',
                    'Aberto',
                    'Consulta interna',
                    'Finalizado',
                  ],
                  item.status
                ) &&
                solicitacaoEnviarAprovacao &&
                item.statusCarta !== null &&
                (mesaId === null || mesaId === 1)
              "
            >
              <v-list-item-title>Encaminhar para aprovação</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="$emit('consulta', item.id)"
              v-if="
                !checkStatus(
                  [
                    'Pendente de aceite',
                    'Aberto',
                    'Consulta interna',
                    'Finalizado',
                  ],
                  item.status
                ) &&
                solicitacaoConsultaInterna &&
                (mesaId === null || mesaId === 1)
              "
            >
              <v-list-item-title
                >Encaminhar para consulta interna</v-list-item-title
              >
            </v-list-item>

            <v-list-item
              @click="goTo('CartasRespostaEditar', item.id)"
              v-if="
                checkStatus(['Em aprovação'], item.status) &&
                checkStatus(['Em aprovação'], item.statusCarta) &&
                item.aprovadorId === user.funcionario.id
              "
            >
              <v-list-item-title>Analisar carta</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="$emit('excluir', item)"
              v-if="
                (checkStatus(
                  ['Pendente de aceite', 'Aberto', 'Troca de mesa'],
                  item.status
                ) &&
                  solicitacaoExcluir &&
                  (mesaId === null || mesaId === 1)) ||
                user.tipo == 'Administrador master'
              "
            >
              <v-list-item-title>Excluir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment";
export default {
  name: "SolicitacoesTabela",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    solicitacoes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageText: {
      type: String,
      default: "-",
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: null,
      page: null,
    },
    iconStatus: {
      Aberto: {
        value: "Aberto",
        color: "#40db6e",
        icon: "mdi-send",
      },
      "Em andamento": {
        value: "Em andamento",
        color: "#F6CF23",
        icon: "mdi-clock-outline",
      },
      "Troca de mesa": {
        value: "Troca de mesa",
        color: "#03223f",
        icon: "mdi-file-edit",
      },
      "Aguardando resposta": {
        value: "Aguardando resposta",
        color: "#f24f00",
        icon: "mdi-message-badge",
      },
      "Consulta interna": {
        value: "Consulta interna",
        color: "#9f9f9f",
        icon: "mdi-timer-sand",
      },
      Respondido: {
        value: "Respondido",
        color: "#ff7900",
        icon: "mdi-message-check",
      },
      "Pendente de aceite": {
        value: "Pendente de aceite",
        color: "#00ffff",
        icon: "mdi-account-lock",
      },
      "Em aprovação": {
        value: "Em aprovação",
        color: "#993399",
        icon: "mdi-check-decagram",
      },
      Reprovada: {
        value: "Reprovada",
        color: "#cf0e0e",
        icon: "mdi-alert-circle",
      },
      Aprovada: {
        value: "Aprovada",
        color: "#008000",
        icon: "mdi-check-circle",
      },
      Finalizado: {
        value: "Finalizado",
        color: "#cf0e0e",
        icon: "mdi-email-check",
      },
      "Aguardando envio": {
        value: "Aguardando envio",
        color: "#906437",
        icon: "mdi-email",
      },
    },
  }),
  methods: {
    handleFilter(event) {
      (this.formData.orderBy = event.sortBy[0]),
        (this.formData.orderSorted = event.sortDesc[0] ? "DESC" : "ASC"),
        (this.formData.perPage = event.itemsPerPage),
        (this.formData.page = event.page);
      this.$emit("handleFilter", this.formData);
    },
    goTo(rota, id) {
      this.$router.push({ name: rota, params: { id: id } });
    },
    getRowStyle(item) {
      return `{background-color:" ${item.cor}}`;
    },
    checkStatus(status, status_check) {
      return status.indexOf(status_check) >= 0;
    },
  },
  computed: {
    cartaExibir() {
      return this.$store.state.user.data.permissoes.includes("carta.exibir");
    },
    solicitacaoExibir() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.exibir"
      );
    },
    solicitacaoExcluir() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.excluir"
      );
    },
    solicitacaoEditar() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.editar"
      );
    },
    solicitacaoIniciarAtendimento() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.iniciarAtendimento"
      );
    },
    solicitacaoEncaminhar() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.encaminhar"
      );
    },
    solicitacaoCriarPostagem() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.criarPostagem"
      );
    },
    solicitacaoEnviarAprovacao() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.enviarAprovacao"
      );
    },
    solicitacaoConsultaInterna() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.consultaInterna"
      );
    },
    mesaId() {
      return this.$store.state.user.data.funcionario.mesaId;
    },
    user() {
      return this.$store.state.user.data;
    },
    isRepresentante() {
      return this.$store.state.user.data.tipo !== "Representante"
    },
  },
  filters: {
    dataPtBrAbertoDias: (value) => {
      let agora = moment();
      let dias = agora.diff(value, "days");
      return "Há " + dias + " dias";
    },
    dataPtBrAberto: function (value) {
      if (value) {
        return moment(value).format("DD/MM/Y HH[h]:mm[m]");
      } else {
        return null;
      }
    },
    dataTimePtBr: function (value) {
      if (value) {
        return moment(value).format("DD/MM/Y HH[h]:mm[m]");
      } else {
        return null;
      }
    },
  },
};
</script>
<style lang="scss">
.tabela-solicitacoes tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-solicitacoes tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  padding: 0px 4px;
}
</style>
